<template>
  <NuxtLink
    :to="asAnchor ? '#top-page' : localePath('/')"
    :external="asAnchor"
    v-bind="$attrs"
    class="contents"
  >
    <span class="sr-only">{{ companyLogo.alt }}</span>
    <img
      :src="companyLogo.src"
      :alt="companyLogo.alt"
      :class="imgClass"
      loading="eager"
    >
  </NuxtLink>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { useLocalePath } from '#imports';

const localePath = useLocalePath();
const webSettings = inject('settings') as Record<string, any>;

defineOptions({
  inheritAttrs: false,
});

defineProps({
  imgClass: {
    type: String,
    default: 'w-auto max-h-20',
  },
  asAnchor: {
    type: Boolean,
    default: false,
  },
});

const companyLogo = computed(() => {
  const logo = webSettings?.settings?.logo || {};

  return {
    src: logo?.meta?.download_url || '',
    alt: logo?.title || 'Company logo',
  };
});
</script>
